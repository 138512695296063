import React, { useEffect, useRef, useContext, useState } from "react"
import { TimelineMax, TweenLite, Power3, gsap } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"
import { isMobileOnly } from "react-device-detect"
import { Controller, Scene } from "react-scrollmagic"
import data from "../../data/ProjectData.json"

import { GlobalDispatchContext, GlobalStateContext } from "./context"
import RightArrow from "../components/rightArrow.js"
import Social from "./social.js"

const Projects = ({ setCurrentProject, setProjectData }) => {
  const countLimit = 2
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  let _projectHeader = useRef(null)
  let _firstProjectParent = useRef(null)
  let _todoList = useRef(null)
  let _reactagram = useRef(null)
  let _title1 = useRef(null)
  let _title2 = useRef(null)
  let _title3 = useRef(null)
  let _title4 = useRef(null)
  let _title5 = useRef(null)
  let _title6 = useRef(null)
  let _title7 = useRef(null)
  let _title8 = useRef(null)
  let _title9 = useRef(null)
  let _title10 = useRef(null)
  let timeline = new TimelineMax({ paused: true })

  const projectModalToggle = (a, b, data) => {
    let projectTitle = b ? b.textContent.split(" ") : ["0", "0"]

    if (a && b && data) {
      if (projectTitle.length >= 3) {
        projectTitle = projectTitle.slice(0, projectTitle.length - 2)
      } else {
        projectTitle = projectTitle.slice(0, 1)
      }
      setCurrentProject({
        className: a ? a : null,
        title: projectTitle.join(" "),
      })
      setProjectData({
        description: data.description,
        gitLink: data.githubLink,
        deployLink: data.deploymentLink,
      })
    }

    setTimeout(() => {
      if (!state.presentationModalOpen) {
        dispatch({ type: "presentation_modal_open" })
        if (typeof document !== "undefined")
          document.getElementById("Orginal-burger").style.visibility = "hidden"
      }
    }, 300)
  }

  useEffect(() => {
    gsap.registerPlugin(CSSPlugin)
    document.documentElement.style.setProperty("--social-color", "#fafbfd")
    timeline
      .add(
        TweenLite.to(_projectHeader, 1, {
          css: { lineHeight: isMobileOnly ? "4.5rem" : "10rem" },
          ease: Power3.easeOut,
          delay: state.landingAnimationCount < countLimit ? 8 : 0,
        })
      )
      .add(
        TweenLite.to(
          _firstProjectParent,
          state.landingAnimationCount < countLimit ? 0.7 : 0.3,
          { marginTop: "0", ease: Power3.easeOut }
        )
      )
      .add("end")
      .to(
        _todoList,
        0.4,
        {
          css: { backgroundColor: "transparent" },
          ease: Power3.easeOut,
          delay: 0.2,
        },
        "end"
      )
      .to(
        _reactagram,
        0.4,
        {
          css: { backgroundColor: "transparent" },
          ease: Power3.easeOut,
          delay: 0,
        },
        "end"
      )
      .add("group1")
      .to(
        _title1,
        state.landingAnimationCount < countLimit ? 0.75 : 0.4,
        { css: { y: "0", color: "#fff" }, ease: Power3.easeOut, delay: 0 },
        "group1"
      )
      .to(
        _title2,
        state.landingAnimationCount < countLimit ? 0.9 : 0.4,
        { css: { y: "0", color: "#fff" }, ease: Power3.easeOut, delay: 0.4 },
        "group1"
      )
      .play()
    dispatch({ type: "landing_animation_count" })
  }, [])

  return (
    <div className="Project-section Section-container">
      <div className="Section-header-section section">
        <div className="Section-header">
          <h2 ref={el => (_projectHeader = el)}>projects.</h2>
        </div>
        <div
          className="Project-parent First-Parent"
          ref={el => (_firstProjectParent = el)}
        >
          <div
            className="Project-child Dashboard-demo"
            role="Presentation"
            onClick={() =>
              projectModalToggle(
                "Dashboard-demo-presentation",
                _title1,
                data[0]
              )
            }
          >
            <div className="Dashboard-demo-background background"></div>
            <div className="Project-title">
              <p className="Title-parent d23ffw33" ref={el => (_title1 = el)}>
                {" "}
                <span className="Title">Dashboard Demo</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
          <div
            className="Project-child Reactagram"
            role="Presentation"
            onClick={() =>
              projectModalToggle("Reactagram-presentation", _title2, data[1])
            }
          >
            <div
              className="Reactagram-background background"
              ref={el => (_reactagram = el)}
            ></div>
            <div className="Project-title">
              <p className="Title-parent d23ffw33" ref={el => (_title2 = el)}>
                {" "}
                <span className="Title">Reactagram</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Section-header-section section">
        <div className="Project-parent">
          <Controller>
            <Scene
              duration={0}
              classToggle={[".Navbar-container-parent", "Scrolled"]}
              reverse={true}
              offset={
                -Math.max(
                  (typeof document !== "undefined" &&
                    document.documentElement.clientHeight) / 2
                )
              }
              indicators={false}
              triggerHook={0.25}
            >
              <div
                className="Project-child Todo-list"
                role="Presentation"
                onClick={() =>
                  projectModalToggle("Todo-list-presentation", _title3, data[2])
                }
              >
                <div
                  className="Todo-list-background background"
                  ref={el => (_todoList = el)}
                ></div>
                <div className="Project-title">
                  <p className="Title-parent" ref={el => (_title3 = el)}>
                    {" "}
                    <span className="Title">Todo List</span>
                    <br />
                    <span className="Arrow">
                      <RightArrow /> view project.
                    </span>
                  </p>
                </div>
              </div>
            </Scene>
          </Controller>
          <div
            className="Project-child Newsington-news"
            role="Presentation"
            onClick={() =>
              projectModalToggle(
                "Newsington-news-presentation",
                _title4,
                data[3]
              )
            }
          >
            <div className="Newsington-news-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title4 = el)}>
                {" "}
                <span className="Title">Newsington News</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
          <div
            className="Project-child Aqquua"
            role="Presentation"
            onClick={() =>
              projectModalToggle("Aqquua-presentation", _title5, data[4])
            }
          >
            <div className="Aqquua-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title5 = el)}>
                {" "}
                <span className="Title">Aqquua</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
          <div
            className="Project-child Guess-the-temp"
            role="Presentation"
            onClick={() =>
              projectModalToggle(
                "Guess-the-temp-presentation",
                _title6,
                data[6]
              )
            }
          >
            <div className="Guess-the-temp-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title6 = el)}>
                {" "}
                <span className="Title">Guess The Temp</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Section-header-section section">
        <div className="Project-parent">
          <div
            className="Project-child Z-the-pro"
            role="Presentation"
            onClick={() =>
              projectModalToggle("Z-the-pro-presentation", _title7, data[7])
            }
          >
            <div className="Z-the-pro-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title7 = el)}>
                {" "}
                <span className="Title">Z The Pro</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
          <div
            className="Project-child Ice-and-fire"
            role="Presentation"
            onClick={() =>
              projectModalToggle("Ice-and-fire-presentation", _title8, data[5])
            }
          >
            <div className="Ice-and-fire-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title8 = el)}>
                {" "}
                <span className="Title">Ice & Fire</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
          <div
            className="Project-child Wristadvisor"
            role="Presentation"
            onClick={() =>
              projectModalToggle("Wristadvisor-presentation", _title9, data[8])
            }
          >
            <div className="Wristadvisor-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title9 = el)}>
                {" "}
                <span className="Title">Wristadvisor</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
          <div
            className="Project-child Snake"
            role="Presentation"
            onClick={() =>
              projectModalToggle("Snake-presentation", _title10, data[9])
            }
          >
            <div className="Snake-background background"></div>
            <div className="Project-title">
              <p className="Title-parent" ref={el => (_title10 = el)}>
                {" "}
                <span className="Title">Snake</span>
                <br />
                <span className="Arrow">
                  <RightArrow /> view project.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {isMobileOnly && (
        <div className="section fp-auto-height" id="Social-mobile-only">
          <Social />
        </div>
      )}
    </div>
  )
}

export default Projects
