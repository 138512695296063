import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import ReactFullpage from '@fullpage/react-fullpage';
import { TimelineMax, TweenLite, Power3, gsap } from "gsap";
import { CSSPlugin } from 'gsap/CSSPlugin';

import { GlobalDispatchContext, GlobalStateContext } from "../components/context";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Projects from "../components/projects";
import { isMobileOnly } from "react-device-detect";


function IndexPage() {
  const [projectData, setProjectData] = useState({ description: "", gitLink: "", deployLink: "" });
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [currentProject, setCurrentProject] = useState({ className: "", title: "" });
  let _presentationTop = useRef(null);
  let _presentationBottom = useRef(null);
  let _presentationTitle = useRef(null);
  let presentationTl = new TimelineMax({ paused: true });
  let presentationTlClose = new TimelineMax({ paused: true });


  const presentProject = () => {
    return (
      <div className="Presentation-container">
        <div className={`Presentation-top background ${currentProject.className}`} ref={el => _presentationTop = el} style={{ backgroundImage: currentProject.backgroundImageSrc }}>
          <div className="Burger-menu-container" id="Burger">
            <div className={`Burger-menu Front`}></div>
            <div className={`Burger-menu Back`} onClick={() => presentationAnimationClose()}></div>
          </div>
          <p className="Prsentation-title" ref={el => _presentationTitle = el} >{currentProject.title}</p>
        </div>
        <div className="Presentation-bottom" ref={el => _presentationBottom = el}>
          <div className="Presentation-bottom-left">
            <p className="Project-description">{projectData.description}</p>
          </div>
          <div className="Presentation-bottom-right">
            <a href={projectData.gitLink} target="_blank" rel="noopener noreferrer" className="Button Presentation">github</a>
            <a href={projectData.deployLink} target="_blank" rel="noopener noreferrer" className="Button Presentation">launch</a>
          </div>
        </div>
      </div>
    )
  }

  const presentationAnimation = () => {
    presentationTl
      .add("presentation")
      .call(() => { if (typeof document !== 'undefined') document.querySelectorAll('.Navbar-container-parent')[1].classList.add("Scrolled") }, null, null, 1)
      .to(_presentationTop, 1, { css: { width: "100%" }, ease: Power3.easeInOut, delay: 0.2 }, "presentation")
      .to(_presentationBottom, 0.4, { css: { y: 0 }, ease: Power3.easeInOut, delay: 1 }, "presentation")
      .add(TweenLite.to(_presentationTitle, 0.7, { css: { lineHeight: isMobileOnly ? "3.5rem" : "6rem" }, ease: Power3.easeInOut, delay: 0.1 }))
      .add(TweenLite.to(document !== 'undefined' && document.querySelectorAll('.Navbar-container-parent')[0], 0, { css: { y: -200, transition: "0.4s all cubic-bezier(.34,-0.14,.57,1.07)" }, delay: 0 }))
      .call( () => { if (typeof document !== 'undefined') document.getElementById('Burger').classList.add("is-flipped") }, null, null)
      .play()
    if (typeof window !== 'undefined') window.fullpage_api.setAllowScrolling(false)
  }

  const presentationAnimationClose = () => {
    presentationTlClose
      .add(TweenLite.to(_presentationTitle, 0.7, { css: { lineHeight: "0rem" }, ease: Power3.easeInOut, delay: 0.2 }))
      .add(TweenLite.to(document !== 'undefined' && document.querySelectorAll('.Navbar-container-parent')[0], 0.4, { css: { y: 0, transitionTimingFunction: "cubic-bezier(.34,-0.14,.57,1.07)" }, ease: Power3.easeInOut, delay: 0 }))
      .call(() => { if (typeof document !== 'undefined') document.getElementById('Burger').classList.remove("is-flipped") }, null, null, 1)
      .add(TweenLite.to(document !== 'undefined' && document.getElementById('Orginal-burger'), 0, { css: { visibility: "visible" }, delay: 1 }))
      .add("presentation")
      .to(_presentationBottom, 0.6, { css: { y: "50vh" }, ease: Power3.easeInOut, delay: 0 }, "presentation")
      .to(_presentationTop, 0.6, { css: { width: "0%" }, ease: Power3.easeInOut, delay: 0.3 }, "presentation")
      .call(() => {
        if (typeof document !== 'undefined') {
          if (document.getElementById('fullpage').style.transform) {
            if (parseInt(document.getElementById('fullpage').style.transform.split(",")[1].match(/(\d+)/g)) < window.innerHeight - 1) document.querySelectorAll('.Navbar-container-parent')[1].classList.remove("Scrolled")
          }
        }
      }, null, null, 1)
      .play()
    if (typeof window !== 'undefined') window.fullpage_api.setAllowScrolling(true)
    setTimeout(() => {
      dispatch({ type: "presentation_modal_open" })
    }, 4500);
  }

  useEffect(() => {
    gsap.registerPlugin(CSSPlugin)
    if (state.presentationModalOpen) { presentationAnimation() }
    return () => {

    };
  }, [state.presentationModalOpen])

  const Fullpage = useCallback(() => (
    <ReactFullpage
      licenseKey={'93C12D00-C8B440C7-9B7AFBDA-5C719E3A'}
      scrollingSpeed={700}
      navigation="true"
      navigationPosition="left"
      autoScrolling={true}
      scrollBar={false}


      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Projects
              setCurrentProject={setCurrentProject}
              setProjectData={setProjectData}
            />
          </ReactFullpage.Wrapper>
        );
      }}
    />
  ), []);

  return (
    <Layout>
      <SEO title="projects" />
      {state.presentationModalOpen && presentProject()}
      <div className="Home-container" id="fullpage">
        <Fullpage />
      </div>
    </Layout>
  )

};

export default IndexPage;
