import React from 'react';

export default function RightArrow() {
        return (
            <React.Fragment>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="40px" height="50%" viewBox="0 0 2456.000000 1412.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g className="g" fill="#fff" stroke="none" >
                    <path d="M13662 13311 c-186 -65 -314 -193 -378 -376 -24 -69 -28 -96 -28
                    -190 0 -133 23 -219 85 -316 30 -48 597 -621 2227 -2251 l2187 -2188 -7180 0
                    c-6287 0 -7188 -2 -7242 -15 -173 -41 -317 -154 -397 -313 -117 -231 -71 -506
                    114 -693 85 -85 178 -139 281 -164 57 -13 882 -15 7244 -15 l7180 -1 -2176
                    -2172 c-1197 -1195 -2190 -2191 -2208 -2214 -81 -106 -127 -262 -118 -400 17
                    -251 170 -451 409 -534 70 -24 99 -29 183 -29 116 0 180 15 285 66 74 36 127
                    88 2826 2788 2719 2719 2751 2752 2788 2827 86 180 86 355 0 535 -38 79 -39
                    80 -2788 2830 -2700 2700 -2752 2752 -2826 2788 -105 51 -169 66 -285 66 -84
                    0 -113 -5 -183 -29z"/>
                    </g>
                </svg>
            </React.Fragment>
    )
}